body {
  overflow-x: hidden;
}

// img, svg, i, a {
//   transition: all 0.1s linear;
// }

*:focus {
  outline: none !important;
}

button:focus {outline:0;}

.serif-ff {
  font-family: $serif-ff;
  letter-spacing: 3px;
}

ul {
  list-style-type: none;
  margin-bottom: 0;
  margin-left: 0;
}

[type='number'] {
  text-align: right;
}

.title {
  text-transform: capitalize;
}

.amount, .qty {
  text-align: right;
}

img {
  &.bird {
    &.face-right {
      transform: scale(-1, 1);
    }
  }
}

.page {
  position: relative;
  padding: 15px 0.5rem ;
  > section {
    // padding-top: 1rem;
  }
  &.page-login {
    max-width: 640px;
    margin: auto;
    .page-title {
      font-size: 4rem;
    }
    section {
      background-color: #efefef;
      position: relative;
      padding: 1rem 2rem 3rem;
      @include phone {
        padding: 1rem 1rem 3rem;
      }
    }
    .section-title {
      font-size: 1.4rem;
    }
    .section-header {
      padding: 1rem;
      width: 50%;
      margin: auto;
      border-bottom: 2px solid #ccc;
      text-align: center;
      margin-bottom: 1rem;
      p {
        margin-bottom: 0;
        font-size: 0.875rem;
      }
    }
    .section-content {
      padding: 2rem;
    }
    .logout {
      text-align: center;
      padding-top: 4rem;
    }
    .google-signin {
      > div {
        margin: auto;
      }
    }
    input:not(.button), option {
      height: 1.6rem;
      line-height: 1.6;
      font-size: 0.875rem;
    }
    .form-item {
      margin-bottom: 0.5rem;
    }
    .button {
      margin-top: 1.6rem;
    }
    label {
      text-transform: uppercase;
    }
    .required-info {
      position: absolute;
      left: 1rem;
      bottom: 0;
    }
  }
}

.no-stuffs {
  max-width: 420px;
  margin: auto;
  padding: 2rem 1rem;
}

.page-title {
  text-align: center;
  padding: 1rem 1rem 0;
  text-transform: capitalize;
  font-size: 1.6rem;
  margin-bottom: 0;
  h1 {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  small {
    text-align: center;
  }
  margin-bottom: 1rem;
}

.page-subtitle {
  text-align: center;
  color: #999;
}

.page-header {
  padding: 2rem 0;
  .page-title {
    padding: 0;
  }
}

button, .button {
  cursor: pointer;
  font-size: 1.2rem;
  text-transform: uppercase;
  // @extend .serif-ff;
}

.appContainer {
  // background-color: #efefef;
  max-width: $global-width;
  margin: auto;
}

.form-item {
  margin-bottom: 1rem;
}

.page-actions {
  margin: 2rem auto;
}

h1, h2, h3, h4, h5, h6 {
  // @extend .serif-ff;
  letter-spacing: 0;
  text-transform: capitalize;
}

input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"], input[type="color"], textarea, input[type="file"], input[type="checkbox"], input[type="radio"], select {
  margin-bottom: 0;
}

// ****************************************************************************
// *                       screen and preloader settings                      *
// ****************************************************************************

.screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: hsla(0, 100%, 100%, 0.8);
  z-index: 998;
  display: none;
}

.menu-screen {
  background-color: transparentize(#000, 0.8);
}

.mobile-menu-wrapper, .action-menu-wrapper, .user-menu-wrapper {
  padding-top: 1rem;
  display: none;
  z-index: 999;
  background-color: #fff;
  height: 100vh;
  width: 320px;
  position: fixed;
  top: 0;
  text-transform: capitalize;
  box-shadow: 0 2px 20px #000;
  .close-button {
    color: #fff;
  }
}

.screen {
  .loader-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 360px;
    margin: auto;
    text-align: center;
  }
  .logo {
    max-width: 240px;
    margin: auto;
  }
  .loading {
    width: auto;
  }
}

.action-menu-wrapper, .user-menu-wrapper {
  right: 0;
}

.mobile-menu, .action-menu, .user-menu {
  .menu-top {
    padding: 1rem;
    a {
      border: none;
    }
  }
  a, .list-header span {
    color: $anchor-color;
    font-size: 0.8rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: $header-font-family;
    padding: 1rem;
    display: block;
    border-bottom: solid 1px $mute-blue;
  }
  .list-header span {
    // padding-top: 1.5rem;
  }
  .list-within a {
    border-bottom: solid 1px $pale-blue;
  }
}

#now-loading {
  display: none;
}

body {
  &.menu-open, &.now-loading {
    overflow: hidden;
    .screen {
      // background-color: transparentize(#fff, 0.2);
   
    }
  }
  &.now-loading {
    #now-loading {
      z-index: 9999;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }
}

nav {
  // font-family: $header-font-family;
}

header {
  // box-shadow: 0 3px 10px #ccc;
  z-index: 9999;
}

.nav-scroll {
  padding: 1rem;
  background-color: $creme;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  .logo {
    width: 90px;
  }
  @include tablet-down {
    display: none;
    padding: 0;
  }
}

.backtotop {
  position: fixed;
  right: 2rem;
  opacity: 0;
  visibility: hidden;
  bottom: -2rem;
  transition: all 0.3s linear;
  a {
    opacity: 0.6;
    &:hover {
      opacity: 1;
    }
  }
}

body.scrolled {
  .backtotop {
    bottom: 2rem;
    opacity: 1;
    visibility: visible;
    .button {
      background-color: transparentize(#000, 0.6);
      padding: 0.5rem;
    }
  }
}

a, .button, button, i, .input-group-label, svg, img, a {
  transition: all 0.15s linear;
}

// ****************************************************************************
// *                                  header                                  *
// ****************************************************************************

header {
  border-bottom: solid 3px $primary-color;
}

.header-top {
  // background-color: $salmon;
  // background-color: $primary-color;
  // background-color: $accent-color;
  // padding: 0.25rem;
  a {
    // color: #fff;
    padding: 0.25rem 0.5rem;
    display: block;
    &:hover {
    color: $accent-color;
    }
  }
  i, svg {
    font-size: 1.4rem;
    vertical-align: middle;
    &.fa-shopping-cart, &.fa-envelope {
      position: relative;
      top: -0.15rem;
    }
  }
  .is-dropdown-submenu {
    background-color: lighten($accent-color, 5%);
    border: solid 1px darken($accent-color, 10%);
    z-index: 999;
  }
  .is-dropdown-submenu-item a {
    width: 100%;
  &:hover {
      background-color: $primary-color;
    }
    // background-color: darken($white, 10%);
  }
  .hicons > li {
    &.is-active {
      i, svg {
        color: #ccc;
      } 
    }
  }
  .cart-icon {
    position: relative;
    .bag-count {
      // position: absolute;
      // top: 20px;
      // right: 22px;
      // background-color: transparentize(#333, 0.3);
      color: #fff;
      line-height: 1;
    }
  }
}

.main-logo {
  img {
    max-width: 320px;
    padding: 2rem 2rem 1rem;
    max-height: 180px;
    width: auto;
  }
}

.main-menu {
  margin-bottom: 5px;
  background-color: #FFF;
  // margin-bottom: 1rem;
  button {
    padding: 0.5rem 1rem;
    font-size: 2rem;
    color: $primary-color;
    position: relative;
    top: 0.2rem;
    i, svg {
      vertical-align: middle;
    }
  }
  a {
    // font-family: $serif-ff;
  }
  .logo {
    a {
      img {
        max-height: 48px;
        width: auto;
        padding-left: 1rem;
      }
      // font-family: $serif-ff;
      @extend .serif-ff;
      text-transform: uppercase;
      letter-spacing: 4px;
      display: block;
      font-size: 2rem;
      line-height: 90px;
      @include phone {
        line-height: 60px;
        font-size: 1.8rem;
      }
    }
  }
  // .is-dropdown-submenu {
  //   background-color: lighten($accent-color, 5%);
  //   border: solid 1px darken($accent-color, 10%);
  // }
  // .is-dropdown-submenu-item a {
  //   width: 100%;
  //   &:hover {
  //     background-color: $primary-color;
  //   }
  //   // background-color: darken($white, 10%);
 
  // }
  li:not(.logo) {
    a {
      border: solid 1px #fff;
      text-transform: uppercase;
      letter-spacing: 2px;
      &:hover, &:active, &:focus {
        // background-color: $primary-color;
        color: darken($accent-color, 10%);
        // color: lighten($accent-color, 20%);
        // border-color: $accent-color;
      }
    }
  }
  .dropdown .is-active > a {
    // background-color: $primary-color;
    // color: lighten($accent-color, 20%);
    color: darken($accent-color, 10%);
  }
  .dropdown .is-dropdown-submenu a {
    width: 100%;
    &:hover {
      color: #fff;
      background-color: $salmon;
    }
  }
}

.hicons.dropdown.menu {
  a {
    padding: 0.5rem;
  }
  i, svg {
    font-size: 0.9rem;
  }
}

.nav-social-links {
  a {
    display: block;
  }
  i, svg {
    font-size: 1rem;
    padding: 0.25rem 0.5rem;
  }
}

.page {
  @include xy-grid-container;
  max-width: 1024px;
  margin: auto;
}

// ****************************************************************************
// *                                   index                                  *
// ****************************************************************************



.product-list {
  .product-list-item {
    // padding: 2rem;
    background-color: #fff;
    border: solid 3px $white;
    padding: 0.25rem;
    padding-top: 0;
    .product-labels {
      padding-bottom: 0;
      font-weight: 700;
      // text-transform: capitalize;
      // font-size: 1.2rem;
      line-height: 1.4;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 400;
      font-size: 0.75rem;
      font-style: italic;
    }
    .brand-title {
      text-transform: capitalize;
      line-height: 1.4;
    }
    .product-title {
      // padding-top: 0.5rem;
      // text-transform: uppercase;
      font-family: $display-ff;
      font-style: italic;
      font-size: 0.8rem;
      line-height: 1.4;
    }
    .product-price {
      font-size: 0.8rem;
      font-weight: 700;
      // font-family: "Lora"
    }
    .desc {
      color: #444;
      font-size: 0.8rem;
      font-style: italic;
    }
  }
  .product-info {
    padding: 0.5rem 0.5rem;
    text-align: left;
    border-top: solid 1px $pale-blue;
  }
  .product-content {
    padding: 0.8rem 1rem 0.5rem;
  }
  .icon-occasions {
    img {
      width: 24px;
      border: solid 2px #fff;
    }
  }
  .product-list-item {
    .product-image {
      position: relative;
      overflow: hidden;
      img {
      }
      img:first-child {
        transition: all 0.2s linear;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: auto;
        margin: auto;
        z-index: 1;
        &:hover {
          opacity: 0;
        }
      } 
    }
  }
}



.section-featured-product-list, .section-featured-products, .section-product-list {
  position: relative;
  // margin-bottom: 2rem;
  .section-title {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
  .post-item, .grid-sizer {
    width: 30%;
    @include tablet {
      width: 45%;
    }

    @include phone {
      width: 100%;
    }
  }
  .post-item {
    padding: 1.4rem;
    // margin-bottom: 1rem;
    img {
      // width: 100%;
    }
    // .thumbnail {
    //   margin-bottom: 0.25%;
    //   border: 0;
    //   box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
    //   &:hover {
    //     box-shadow: 0 8px 17px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    //   }
    // }
    &.width-2 {
      width: 66%;
      @include tablet {
        width: 100%;
      }
    }
    .product-image {
      // height: 180px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      @include phone {
        // height: 240px;
      }
    }
    p {
      margin-bottom: 0;
    }
  }
  .gutter-sizer {
    width: 2%;
  }
}

.product-atc {
  border: solid 3px $white;
  border-top: 0;
  i, svg {
    font-size: 2rem;
  }
  .input-group-label {
    background-color: #eee;
    border: none;
  }
  input {
    border: none;
  }
}

.product-atc {
  button {
    background-color: $salmon;
    // background-color: lighten($salmon, 20%);
    &:hover {
      background-color: darken($salmon, 10%);
    }
  }
}

.section-product-atc {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: lighten($primary-color, 20%);
  text-align: center;
  padding: 1rem 0;
  &:hover {
    // background-color: $primary-color;
 
  }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus, textarea:focus {
    border: none;
  }
  .product-atc {
    border-top: solid 3px $white;
    max-width: 480px;
    margin: auto;
  }
}

// ****************************************************************************
// *                                  footer                                  *
// ****************************************************************************

.section-newsletter {
  padding: 2rem 2rem 6rem;
  @include phone {
    padding: 1rem 1rem 4rem;
  }
  .newsletter-text {
    @include phone {
      padding-bottom: 0.25rem;
    }
  }
  .section-title {
    flex-wrap: nowrap;
    background: linear-gradient(180deg, 
        rgba(0,0,0,0) calc(50% - 1px), 
        rgba(192,192,192,1) calc(50%), 
        rgba(0,0,0,0) calc(50% + 1px)
    );
    padding: 2rem;
    img, svg {
      width: 90px;
    }
    > div {
      background-color: #FFF;
      &:first-child {
        padding-left: 1rem;
      }
      &:last-child {
        padding-right: 1rem;
      }
    }
    @include phone {
      h4 {
        font-size: 1rem;
      }
    }
  }
  .text-title {
    padding: 2rem;
    font-weight: 700;
    color: #666;
    @include phone {
      padding: 2rem 1rem;
      
    }
  }
  .subtitle {
    text-align: center;
    margin-bottom: 0;
    font-style: italic;
  }
  // background-color: $pale-blue;
  .newsletter {
    max-width: 720px;
    margin: auto;
  }
  a, .button, .input-group .input-group-label {
    background-color: $black;
    color: #fff;
      &:hover {
      background-color: $black;
    }
  }
  .newsletter-form {
    position: relative;
    @include tablet-down {
      width: 90%;
      margin: auto;
    }
  }
  input, .input-group {
    margin-bottom: 0;
    line-height: 4;
    height: 4rem;
    padding-left: 2rem;
    &::placeholder {
      font-style: italic;
      font-size: 0.9rem;
    }
  }
  p {
  }
  a {
    font-size: 0.9rem;
    font-weight: 700;
    padding: 0.7rem 2rem;
    position: absolute;
    right: 0.8rem;
    top: 0.8rem;
  }
}

footer {
  background-color: lighten($salmon, 17%);
  // border-top: solid 6px $accent-color;
  color: $mute-blue;
  padding: 1rem 1rem;
  margin-top: 3rem;
  a {
    text-transform: capitalize;
  }
  // padding-bottom: 5rem;
  
}

.footer-top {
  // background-color: darken($primary-color, 10%);
  // background-color: $accent-color;
  padding-top: 3rem;
  max-width: $global-width;
  margin: auto;
  text-align: left;
  .footer-links {
    border-left: solid 2px lighten($brown, 60%);
    padding-left: 1.5rem;
    &:first-child {
      border: none;
      padding-left: inherit;
    }
  }
  .block-title {
    // padding-bottom: 0.5rem;
    font-size: 0.8rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding-top: 1rem;
    color: $anchor-color;
  }
  a {
    // color: $mute-blue;
    font-size: 0.8rem;
    padding: 0.25rem 0;
    display: block;
    &:hover, &:focus {
      // color: lighten($accent-color, 10%);
    }
  }
  > div {
    margin-bottom: 1rem;
  }
  .newsletter {
    margin-bottom: 3rem;
  }
  ul {
    padding-left: 0;
    margin-left: 0;
  }
  .contact-list {
    text-align: center;
    i, svg {
      margin: auto;
      width: 40px;
      text-align: center;
      font-size: 1.6rem;
      vertical-align: middle;
      // display: block;
      // text-align: center;
    }
    li {
      padding: 0.25rem 0;
    }
    a {
      // text-transform: uppercase;
      vertical-align: middle;
      &:hover {
        color: darken($anchor-color, 10%);
      }
    }
  }
  
}

.footer-logo {
  max-width: 120px;
  margin: auto;
  padding-top: 1rem;
}

.social-links, .post-share {
  margin-top: 2rem;
  .menu {
    padding-top: 1rem;
    a {
      // padding: 0.5rem 1rem;
      background-color: #FFF;
      border-radius: 50%;
      width: 48px;
      height: 48px;
      margin-right: 0.5rem;
      i, svg, span {
        // display: block;
        color: $mute-blue;
        position: relative;
        top: 8px;
      }
    }
  }
}

.post-share {
  .menu {
    a {
      background-color: #333;
      i, svg, span {
        top: 10px;
        color: #fff;
        font-size: 1.2rem;
      }
    }
  }
}

.footer-bottom {
  // background-color: #FFF;
  border-top: solid 2px $accent-color;
  p {
    margin-bottom: 0;
  }
  .copyright {
    text-align: right;
    font-size: 0.8rem;
    color: $anchor-color;
  }
  padding: 0.5rem;
}

// ****************************************************************************
// *                                   cart                                   *
// ****************************************************************************

.cart-item-list {
  padding: 0.5rem;
  > li {
    .cart-item-image {
      width: 60px;
      // height: 60px;
      // position: absolute;
      // left: 0;
      // top: 10px;
      // border-radius: 50%;
      overflow: hidden;
      // padding-left: 0 !important;
      position: relative;
      // top: -4px;
      img {
        border: solid 2px #fff;
      }
    }
    position: relative;
    padding-right: 30px;
    border-bottom: 1px solid #ccc;
  }
  .cart-item-info {
    > div {
      padding: 0.5rem;
      align-self: center;
    }
    button, input, .input-group, p {
      margin-bottom: 0;
    }
    input {
      max-width: 3rem;
      text-align: right;
    }
  }
  .cart-item-delete {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    a, button {
      color: $alert-color;
      i, svg {
        font-size: 1.4rem;
      }
    }
  }
}

.section-cart-item-list {
  .purchase-total {
    font-weight: 700;
    font-size: 1.4rem;
    // background-color: $accent-color;
    padding: 0.5rem 0;
    border-top: solid 4px #999;
    > div:last-child {
      padding-right: 45px;
    }
  }
  .extra-price {
    line-height: 2;
    padding-bottom: 0.5rem;
    > div:first-child {
      font-weight: 700;
    }
    > div:last-child {
      padding-right: 45px;
    }
  }
}

.section-page-actions {
  // position: absolute;
  // bottom: 0;
  width: 100%;
  padding: 2rem 0;
  .button {
    width: 100%;
    font-weight: 700;
    font-size: 1.4rem;
    display: block;
  }
  .previous {
    text-align: left;
  }
  .next {
    text-align: right;
  }
}

// ****************************************************************************
// *                              product-details                             *
// ****************************************************************************

.slideshow-item {
  img {
    margin: auto;
  }
}

.product-attributes {
  > div {
    margin-bottom: 1rem;
  }
}

.product-images {
  max-width: 640px;
  margin: auto;
  .slick-slide {
    padding: 0.5rem;
  }
}




.carousel-product-list, .product-images, .product-images-main, .carousel-testimonials {
  .slick-prev:before, .slick-next:before {
    color: $accent-color;
    font-size: 45px;
  }
  .slick-prev, .slick-next {
    top: 180px;
    width: 120px;
    height: 120px;
    z-index: 2;
    opacity: 0.6;
    &:hover {
      opacity: 1;
    }
    @include tablet {
      top: 160px;
    }
    @include phone {
      top: 120px;
    }
  }
}

.product-images-main {
  img {
    @include tablet-down {
      height: 480px;
      width: auto;
      max-width: 100%;
    }
  }
  .slick-prev, .slick-next {
    top: 50%;
    transform: translateY(-50%);
  }
}

.product-images-navi {
  max-width: 83px;
  margin: auto;
  .slick-slide {
    border: solid 5px #fff;
    border-bottom: none;
    &:last-child {
      border-bottom: solid 5px #fff;
    }
    // padding-left: 5px;
    // padding-right: 5px;
    // padding: 0.5rem;
  }
}

.carousel-item {
  text-align: center;
  img {
    margin: auto;
  }
}

.carousel-testimonials {
  .slick-slide {
    padding: 1rem;
  }
  .slick-next {
    right: -120px;
  }
  .slick-prev {
    left: -120px;
  }
}

.carousel-wrapper {
  position: relative;
}

.arrow-control {
  transition: all 0.15s linear;
  cursor: pointer;
  position: absolute;
  max-width: 60px;
  top: 160px;
  width: 120px;
  height: 120px;
  z-index: 2;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
  @include tablet {
    top: 140px;
    display: none;
  }
  @include phone {
    top: 100px;
    display: none;
  }
}

.section-fie-emerveille {
  padding: 2rem;
  max-width: 640px;
  margin: auto;
  padding-bottom: 4rem;
  border-bottom: solid 2px #eee;
  .description {
    padding-top: 1rem;
    font-size: 0.9rem;
  }
}

.section-featured-testimonials {
  .section-header {
    background: url('../img/bg/stitch-ribbon.png') repeat-x center center;
    background-size: contain;
    h4 {
      text-align: center;
      font-size: 1rem;
      padding: 0.5rem;
      text-transform: inherit;
    }
  }
}

.slick-slider {
  &:hover {
    .arrow-prev {
      left: -55px;
    }
    .arrow-next {
      right: -55px;
    }
  }
}

.arrow-prev {
  img, svg {
    transform: scale(-1, 1);
  }
  left: -90px;
}

.arrow-next {
  right: -90px;
}

.sizes-list {
  padding-top: 0;
  padding-bottom: 0.5rem;
  span {
    text-transform: uppercase;
  }
}

.page-product-details {
  .section-product-images {
    padding: 2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    .slideshow-navigation {
      img {
        width: 90px;
        height: auto;
      }
      .slick-arrow {
        cursor: pointer;
        height: 35px;
        border: solid 2px #fff;
        &.slick-top {
          i, svg {
            position: relative;
            top: 6px;
          }
        }
        &.slick-bottom {
          position: relative;
          top: 2px;
        }
      }
      img {
        opacity: 1;
      }
      .slick-current {
        img {
          opacity: 0.4;
        }
      }
    }
    .slideshow-main {
      width: 410px;
      height: 600px;
      overflow: hidden;
      .slick-prev {
        left: 0px;
      }
      .slick-next {
        right: 0px;
      }
      img:hover {
        // transform: scale(2);
        // transition: all 0.3s linear;
      }
      @include tablet-down {
        width: 100vw;
        height: 480px;
      }
    }
    .slideshow-navi {
      .slick-prev, .slick-next {
        left: auto;
        right: auto;
      }
      .slick-top {
      }
      .slick-bottom {
        
      }
      .slick-slide {
        padding: 0;
      }
    }
  }
  .brand-title {
    text-transform: capitalize;
    // font-weight: 700;
    font-style: italic;
    h4 {
      margin-bottom: 0;
      font-size: 1.2rem;
      // font-weight: 700;
    }
  }
  .product-title {
    font-family: $display-ff;
    font-style: italic;
    margin-bottom: 0;
    text-transform: capitalize;
    font-size: 1rem;
  }
  .product-attributes {
    .price {
      text-align: center;
      font-size: 1.2rem;
      padding: 1rem;
      font-weight: 700;
    }
  }
  .reservation-form {
    padding-top: 2rem;
    // padding-bottom: 2rem;
  }
  .section-product-images {
    text-align: center;
    img {
      margin: auto;
      // width: 400px;
      // height: 600px;
    }
    .product-images-navi {
      > div {
        cursor: pointer;
      }
    }
  }
  .section-product-info {
    padding: 2rem;
    padding-left: 1rem;
    padding-top: 2.4rem;
  }
  .section-related-products {
    // padding-top: 2rem;
    .section-title {
      text-align: center;
      // text-transform: capitalize;
      h4 {
        text-transform: uppercase;
        font-size: 1.3rem;
      }
      
    } 
    .product-title {
      text-transform: uppercase;
    }
    img {
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .size-chart-button {
    float: right;
  }
  .section-description {
    margin-top: 3rem;
    .accordion-content {
      ul {
        list-style-type: disc;
      }
    }
  }
}

// ****************************************************************************
// *                                 checkout                                 *
// ****************************************************************************

.order-summary {
  padding: 2rem;
  background-color: #fff;
  border-radius: 6px;
  max-width: 640px;
  margin: auto;
  line-height: 2;
  .summary-item {
    border-bottom: solid 1px #ccc;
    line-height: 3;
    &:last-child {
      border: none;
    }
  }
  .item-label {
    text-align: right;
  }
}

.panel {
  &.order-ref {
    padding: 2rem;
    text-align: center;
    font-size: 2.4rem;
    border-radius: 6px;
    background-color: $success-color;
    font-weight: 700;
    letter-spacing: 5px;
    max-width: 640px;
    margin: 2rem auto;
  }
}

.next-step {
  max-width: 640px;
  margin: 2rem auto;
}

// ****************************************************************************
// *                            checkout: selection                           *
// ****************************************************************************

.page-checkout-selection {
  // padding: 0.5rem;
  max-width: 640px;
  margin: auto;
}

// ****************************************************************************
// *                                  contact                                 *
// ****************************************************************************

.page-contact {
  form {
    border-radius: 6px;
    padding: 1rem;
    background-color: transparentize($gold, 0.8);
    max-width: 480px;
    margin: auto;
  }
  .page-title {
    // color: #fff;
  }
  max-width: none;
  // background: url('../img/backtonudes.jpg') no-repeat center center;
  .contact-list {
    .contact-value {
      // text-transform: capitalize;
      font-size: 0.9rem;
    }
  }
}



.g-recaptcha {
  > div {
    margin: 1rem auto;
  }
}

.narrow-page {
  max-width: 640px;
  margin: auto;
}

.payment-images {
  .image-item {
    padding: 1rem;
    max-width: 240px;
    // margin: auto;
 
  }
}

.section-payment-confirmation {
  .image {
    max-width: 60px;
  }
}

.search-box {
  position: relative;
  input + i, input + svg {
    position: absolute;
    top: 0.7rem;
    right: 0.5rem;
  }
}

.search-bar {
  position: relative;
  padding-top: 0.25rem;
  input, input:focus {
    height: 3rem;
    line-height: 2;
    margin: 0;
    font-size: 1.2rem;
    border: none;
    box-shadow: none;
    border-bottom: 1px solid #fff;
    color: #fff;
    background-color: transparent;
    &::placeholder {
      color: #fff;
    }
  }
  i, svg {
    position: absolute;
    top: 0.5rem;
    right: 0.25rem;
    // color: #999;
    color: #fff;
    font-size: 3rem;
  }
}

.logo-band {
  .search-bar {
    max-width: 210px;
    input, input:focus {
      border-bottom: none;
      color: $body-font-color;
      background-color: lighten($creme, 5%);
      font-size: 0.7rem;
      text-transform: uppercase;
      padding-left: 1rem;
      &::placeholder {
        color: #aaa;
        text-transform: uppercase;
      }
    }
    i, svg {
      color: $anchor-color;
      font-size: 0.7rem;
      top: 1.5rem;
      right: 1rem;
    }
  }
  .cart-icon {
    font-weight: 700;
    text-align: right;
    i, svg {
      margin-right: 0.5rem;
    }
    span {
      font-size: 0.7rem;
    }
  }
}

.menu {
  a {
    text-transform: uppercase;
  }
}

.nav-top {
  font-size: 0.7rem;
  font-weight: 700;
  a {
    font-family: $serif-ff;
  }
}
.main-menu {
  a {
    font-family: $serif-ff;
  }
}


.product-images-item {
  padding: 0.5rem;
  @include tablet {
    padding: 1rem;
  }

  @include tablet {
    padding: 1rem;
  }
}

.search-panel {
  display: none;
  background-color: darken($accent-color, 5%);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 2rem;
  z-index: 9999;
  .search-bar {
    margin: auto;
    margin-top: 5rem;
    max-width: 420px;
    i, svg {
      font-size: 2rem;
    }
  }
  .close-button {
    i, svg {
      color: #eee;
      font-size: 3rem;
    }
  }
}

.product-list {
  &.carousel-product-list {
    img {
      margin: auto;
    }
  }
  .brand, .product-labels, .brand-title {
    // text-transform: capitalize;
  }
  text-align: center;
  .product-title {

  }
}

.product-title {
  font-size: 0.8em;
}
.price-rental {}

.price-original {
  color: #999;
  font-style: italic;
}

.social-links {
  i, svg {
    font-size: 1.6rem;
  }
  a:hover i, a:hover svg {
    color: $accent-color;
  }
}

.input-group {
  .input-group-label:hover {
    background-color: darken($accent-color, 10%);
  }
  button {
    margin-bottom: 0;
  }
}

.accordion-title {
  font-size: 1rem;
  text-transform: capitalize;
  // border-bottom: solid 1px #efefef;
  border-bottom: none;
  border-top: solid 1px #efefef;
  // padding-left: 3rem;
  // &::before {
  //   font-size: 1rem;
  //   margin-top: -1rem;
  //   left: 1rem;
  // }
  &:hover, &:focus {
    color: $white;
  }
  padding-top: 1rem;
  padding-bottom: 1rem;
  &::before {
    font-size: 1.4rem;
    margin-top: -0.7rem;
    // left: 1.2rem;
    @include phone {
      // left: 0.5rem;
    }
  }
}


.product-detail-list {
  > div {
    border-bottom: solid 1px #ccc;
    padding: 0.5rem 0;
    .button {
      margin-bottom: 0;
    }
    &:last-child {
      border: none;
    }
  }
}

.product-title, .product-detail-list, .section-product-rental-form {
  @include tablet-down {
    max-width: 480px;
    margin: auto;
    padding: 0;
    text-align: left;
  }
}

.product-color {
  .color-block {
    // margin-right: 0.5rem;
    border: solid 1px #000;
    border-radius: 50%;
    // padding: 10px;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    top: 6px;
    left: -3px;
  }
  .color-title, .filter-title {
    margin-right: 1rem;
    text-transform: capitalize;
  }
}


.main-section {
  position: relative;
  .floating-buttons {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
  }
}

.page-product-list {
  transition: all 0.2s linear;
  max-width: none;
  > aside {
    // display: none;
    padding-top: 60px;
    width: 300px;
    .section-title {
      font-size: 1.2rem;
      font-weight: 700;
      border-bottom: solid 1px #ccc;
    }
    .block-title {
      font-size: 1.2rem;
    }
    > div, section {
      margin-bottom: 1.5rem;
      padding-bottom: 2rem;
      border-bottom: solid 3px #999;
    }
    section:last-child {
      border-bottom: none;
    }
    @include tablet-down {}
  }
  &.show-aside {
    @include desktop {
      // padding-left: 360px;
    }
    @include phone {
      .page-inner {
        transition: opacity 0.15s linear;
        // opacity: 0;
      }
    }
  }
  .section-product-list {
    .block-title {
      font-size: 1.2rem;
      font-weight: 700;
    }
    .block-purchase {
      padding-top: 3rem;
    }
  }
}

.filter-list {
  min-width: 200px;
  @include phone {
    min-width: 300px;
  }
  label {
    padding-left: 0.5rem;
  }
  .filter-items-list {
    label {
      text-transform: capitalize;
      font-size: 1rem;
    }
  }
  .accordion-title:hover, .accordion-title:focus {
    background-color: transparent;
    color: #333;
  }
  .accordion-item {
    &.is-active {
      .accordion-title {
        border-bottom: none;
      }
    }
  }
  .accordion-content {
    padding-top: 0;
  }
  font-size: 0.9em;
  .accordion-title {
    // padding-left: 0.5rem;
  }
  ul {
    margin-left: 0.25rem;
  }
  .color-input {
    padding-right: 0.75rem;
  }
  .color-block {
    display: inline-block;
  }
  .product-color {
    
    label {
      line-height: 1.5;
    }
  }
}

.page-aside {
  padding-top: 75px;
  padding-right: 1rem;
  .close-aside {
    position: absolute;
    top: 1rem;
    right: 0;
    border: none;
    box-shadow: none;
    color: #999;
    svg, i {
      font-size: 2rem;
    }
  }
  .section-title {
    font-size: 1rem;
    // padding-top: 1rem;
    text-transform: uppercase;
    padding-left: 1rem;
  }
  .block-title {
    font-size: 1rem;
  }
  section {
    padding-top: 1rem;
  }
  @include phone {
    display: none;
  }
}

.blog-list {
  .item {}
  .post-image {
    width: 60px;
    height: 60px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    @include phone {
      width: 100%;
      height: 240px;
    }
  }
  .date {
    text-align: right;
  }
  .title {
    font-size: 1.2rem;
  }
}

.collections {
  > li {
    margin: 1rem 0.25rem;
    border-bottom: solid 1px #efefef;
    padding: 1rem 0.25rem;
  }
}

.load-more {
  max-width: 320px;
  margin: 1rem auto;
  padding: 1rem;
}

.page-post-detail {
  small.page-subtitle {
    text-align: center;
    font-size: 1rem;
    // width: 100%;
    display: block;
    margin-bottom: 1rem;
  }
  // .section-related-products {
    .section-title {
      text-align: center;
      font-size: 1.6rem;
    }
  .section-post-share {
    padding-top: 0;
    
  }
}

.video-item {
  position: relative;
  max-width: 360px;
  margin: auto;
  > img {
    width: 100%;
    filter: brightness(50%);
  }
  .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}

.section-post-share {
  text-align: center;
  padding: 2rem 0.5rem;
  a {
    padding: 0.25rem;
  }
  h3 {
    padding-bottom: 1rem;
  }
  i, svg {
    font-size: 2rem;
  }
  a:hover {
    color: $accent-color;
  }
}



.checkbox-wrapper input[type="checkbox"], .radio-wrapper input[type="radio"] {
  visibility: hidden;
  opacity: 0;
  display: none;
  & + label {
    transition: all 0.15s linear;
    background-color: #000;
    color: #fff;
    border: solid 1px #000;
    border-radius: 0s;
    line-height: 2.315rem;
    padding: 0 1rem;
    margin: 0.5rem;
    cursor: pointer;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    @include phone {
      padding: 0 1.5rem;
      margin: 0.5rem auto;
    }

    &.form-actions {
      width: 100%;
      border-radius: 2px;
      text-align: center;
      margin: 1rem auto;
    }
  }
  &:checked + label {
    background-color: $primary-color;
    border-color: darken($primary-color, 10%);
  }
}


.checkbox-wrapper.radio-image-wrapper input[type="checkbox"], .radio-wrapper.radio-image-wrapper input[type="radio"] {
  img {
    object-fit: cover;
  }
  & + label {
    padding: 0;
    margin: 0;
    border-radius: 0;
    border: solid 5px #999;
    img {
      transition: all 0.15s linear;
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
    }
    .title {
      padding: 0.15rem;
      margin-bottom: 0;
    }
  }
  &:checked + label {
    border: solid 5px #000;
    img {
      -webkit-filter: initial; /* Safari 6.0 - 9.0 */
      filter: initial;
    }

  }
}


.icon-checkbox-wrapper input[type="checkbox"] {
  & + label {
    border: none;
    background-color: transparent;
    color: #666;
    margin: 0 auto;
    padding: 0 0.5rem;
    font-size: 1.6rem;
  }
  &:checked + label {
    color: #fff;
    background-color: black;
  }
}




.page-home {
  .section-title {
    text-align: center;
    h4 {
      text-transform: uppercase;
      font-size: 1.4rem;
      @include phone {
        font-size: 1rem;
      }
    }
    .subtitle {
      @include phone {
        font-size: 0.8rem;
      }
    }
  }
}



.section-home-video {
  max-width: 640px;
  margin: auto;
  margin-bottom: 1rem;
  .video-wrapper {
    padding:0;
    margin:0;
    box-sizing: border-box;
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.featured-categories-list {
  padding-bottom: 2rem;
  .slick-slide {
    padding: 0.5rem;
  }
  .item {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    height: 300px;
  }
  .title {
    position: absolute;
    color: #fff;
    font-weight: 700;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 2;
    border-top: solid 2px #fff;
    border-bottom: solid 2px #fff;
    text-transform: uppercase;
    font-size: 0.8rem;
  }
}


.page-home [class^="section-"] {
  // border-top: solid 2px $gold;
  // padding-top: 1rem;
  // margin-top: 3rem;
  .section-title {
    // background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAJklEQVQoU2NkIBIwEqmOgQYKt7bx/SfGekaiFRJjGkgNDTxDrNUAA68EmyEGTn0AAAAASUVORK5CYII=) repeat-x center center;
    // background-repeat: repeat-x;
    // h4 {
    //   background-color: #FFF;
    //   width: 240px;
    //   @include phone {
    //     width: 240px;
    //   }
    //   margin: auto;
    // }
  }
}


.page-lookbook-list {
  .page-title {
    // text-align: left;
  }
  text-align: center;
  .gallery-list-item {
    .title {
      // font-size: ;
      h6 {
        line-height: 2;
      }
    }
    img {
      width: 100%;
      transition: opacity 0.1s linear;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.gallery-item-list {
  position: relative;
  display: block;
  overflow: auto;
  &::after {
    content: "";
    clear: both;
    display: table;
  }
  .gallery-item {
    padding: 0.5rem;
    float: left;
    display: inline-block;
    width: 33%;
    img {
      width: 100%;
      transition: opacity 0.1s linear;
      
      @include desktop {
        height: 465px;
      }
      &:hover {
        opacity: 0.8;
      }
    }
    &.w-2 {
      width: 66%;
    }
    &.w-3 {
      width: 100%;
      padding-right: 1rem;
    }
    @include tablet {
      width: 50%;
      &.w-2 {
        width: 100%;
      }
    }
    @include phone {
      width: 100%;
    }
  }
}

.hotspot-wrapper {
  position: relative;
  .hotspot-dot {
    position: absolute;
    z-index: 1;
    i, svg {
      // color: red;
      color: $gold;
      // color: rgba(229, 0, 137, 0.6);
    }
    .circle {
      -webkit-animation: pulsate 3s ease-out infinite;
      animation: pulsate 3s ease-out infinite;
      display: block;
      position: absolute;
      top: 45%;
      left: 45%;
      width: 2em;
      height: 2em;
      margin: -1em auto auto -1em;
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      border-radius: 50%;
      border: 1px solid $gold;
      // border: 1px solid #E5008A;
      opacity: 0;
    }
  }
  .hotspot-content {
    display: none;
    border: solid 1px #000;
    .product-title {
      text-transform: uppercase;
    }
    .button {
      margin-top: 1rem;
      display: block;
      font-size: 0.5rem;
      padding: 0.25rem 1rem;
    }
    text-transform: capitalize;
    h3 {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}

.hotspot-content {
  .product-image {
    // margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  .em {
    color: #999;
  }
  .button {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    display: block;
  }
  h3 {
    text-transform: capitalize;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .price {
    font-size: 0.875rem;
  }
}

#dipanggil {
  // display: none;
  background-color: #FFF;
  padding: 5rem;
}

.tooltip_templates {
  display: none;
}


.page-product-list {
  > .page-inner {
    max-width: 1200px;
    margin: 0 auto;
    min-height: 100vh;
  }
}

.banner {
  img {
    width: 100%;
  }
}

.section-banner-b {
  .banner-list {
    > .cell {
      padding: 0.4rem;
    }
  }
}

.section-banner-a, .section-banner-b {
  img {
    &:hover {
      opacity: 0.8;
    }
  }
}

// .reveal {
//   // width: 320px;
//   .reveal-title {
//     padding-top: 1rem;
//     text-align: center;
//     font-size: 1.2rem;
//   }
//   .product-image {
//     text-align: center;
//     img {
//       max-width: 240px;
//       margin: auto;
//     }
//   }
//   .product-mini-image {
//     text-align: center;
//     margin: auto;
//     width: 180px;
//   }
//   .product-info {
//     text-align: center;
//     padding: 0.5rem 0 0;
//   }
//   .price {
//     // margin-bottom: 2rem;
//   }
// }




.product-list {
  text-align: center;
  .product-image {
    text-align: center;
  }
  img {
    margin: auto;
  }
}



.page-lookbook-detail {
  .gallery-content {
    padding-top: 13px;
    max-width: 360px;
    margin: auto;
    text-align: center;
  }
}

.reveal {
  padding: 0;
  border: 0;
}


.is-dropdown-submenu.js-dropdown-active {
  z-index: 99;
}

.dropdown.menu .is-active > a {
  color: $mute-blue;
  
}

.dropdown .is-dropdown-submenu a {
  width: 100%;
  display: block;
  &:hover {
    background-color: $salmon;
    color: #fff;
  }
}

.pagination-wrapper {
  margin-top: 2rem;
}

.press-list, .Press-list, .blog-list {
  max-width: 840px;
  margin: auto;
  position: relative;
  display: block;
  // overflow: auto;
  // &::after {
  //   content: "";
  //   clear: both;
  //   display: table;
  // }
  > li {
    padding: 1rem;
  }
  .post-image {
    width: 100%;
    height: auto;
    img {
      width: 100%;
      border-radius: 6px;
    }
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 6px;
  }
  .post-info {
    width: 90%;
    background-color: #fff;
    padding: 0.25rem 0.5rem 0.5rem 1rem;
    position: relative;
    top: -15px;
    border: solid 1px $gold;
    border-radius: 6px;
    text-align: left;
    margin: auto;
  }
  .object-type {
    font-weight: 700;
    color: $gold;
    text-transform: uppercase;
    font-size: 0.75rem;
  }
  .post-date {
    // text-transform: uppercase;
    color: #999;
    font-size: 0.75rem;
  }
  .post-title {
    line-height: 1.2;
    font-size: 1rem;
  }
  .post-list-item {
    padding-bottom: 0;
    &.layout-1 {
      width: 50%;
    }
    &.layout-2 {
      width: 100%;
      .item {
        // width: 60%;
        // margin: auto;
      }
    }
  }
  .grid-sizer {
    width: 50%;
  }
  .gutter-sizer {
    width: 5px;
  }
}

.page-blog-detail {
  .page-title {
    margin-bottom: 0;
    // text-transform: capitalize;
  }
  .news-images {
    max-width: 720px;
    margin: auto;
    .slick-slide {
      padding: 0.5rem;
    }
  }
  .section-content {
    padding: 0 1rem 2rem 1rem;
    max-width: 840px;
    margin: auto;
    font-family: $sans-ff !important;
    p {
      font-family: $sans-ff !important;
    }
  }
}


.gallery-masonry {
  text-align: center;
  margin: auto;

  .gallery-item, .grid-sizer {
    width: 33%;
    @include phone {
      width: 50%;
    }
  }
  .gallery-item {
    padding: 8px;
    img {
      width: 100%;
    }
    .thumbnail {
      margin-bottom: 0.25%;
      border: 0;
      box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
      &:hover {
        box-shadow: 0 8px 17px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
      }
    }
    &.width-2 {
      width: 66%;
      @include tablet {
        width: 100%;
      }
    }
  }
  .gutter-sizer {
    width: 2px;
  }
}

.section-instafeed {
  height: 1px;
  // overflow: hidden;
  // overflow-x: visible;
  margin-bottom: 1rem;
  position: relative;
  .image-title {
    padding-top: 1rem;
    text-align: center;
    font-style: italic;
    margin-bottom: 0;
    img {
      max-width: 240px;
    }
  }
  .subtitle {
    font-size: 0.8rem;
    text-align: center;
  }
  @include desktop {
    // max-width: 720px;
    
    margin: auto;
    margin-bottom: 1rem;
  }
  transition: all 0.2s linear;
  &.visible {
    height: auto;
  }
  .gallery-item {
    padding: 0.5rem;
    img {
      width: 100%;
    }
  }
  .instafeed-wrapper {
    position: relative;
  }
  .arrow-control {
    top: 50%;
    transform: translateY(-50%);
    height: 40px;
  }
}


.slider-wrapper {
  position: relative;
  padding-top: 3rem;
  padding-bottom: 2rem;
  .noUi-connect {
    background-color: $gold;
  }
}



.page-faq {
  section {
    max-width: 640px;
    margin: auto;
    margin-bottom: 1rem;
  }
  .section-title {
    font-size: 1.2rem;
    letter-spacing: 1px;
  }
  .block-title {
    font-size: 1rem;
    margin-bottom: 0;
    // text-transform: capitalize;
  }
  .accordion-title {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
  }
  .faq-item {
    p {
      text-align: justify;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}


.page-contact {
  > div {
    max-width: 720px;
    margin: auto;
  }
  .block-title {
    font-size: 1.2rem;
  }
  .contact-list {
    li {
      padding: 0.5rem;
      text-transform: uppercase;
      line-height: 2;
      border-bottom: solid 1px #ccc;
      &:last-child {
        border-bottom: none;
      }
      i, svg {
        position: relative;
        top: 0.2rem;
        margin-right: 0.5rem;
        width: 30px;
        text-align: center;
        font-size: 1.6rem;
      }
    }
  }
}

/* Animation */
@-webkit-keyframes 
  pulsate {  0% {
   -webkit-transform: scale(1);
   transform: scale(1);
   opacity: 0.8;
  }
   45% {
   -webkit-transform: scale(1.75);
   transform: scale(1.75);
   opacity: 0;
  }
  }
  @keyframes 
  pulsate {  0% {
   -webkit-transform: scale(1);
   transform: scale(1);
   opacity: 0.8;
  }
   45% {
   -webkit-transform: scale(1.75);
   transform: scale(1.75);
   opacity: 0;
  }
}





/* .tooltipster-box */

.tooltipster-sidetip .tooltipster-box {
  background: #fff;
  border: 1px solid $gold;
  border-radius: 4px;
  .product-title {
    text-transform: uppercase;
    font-size: 0.9rem;
  }
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-box {
  margin-top: 8px;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-box {
  margin-right: 8px;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-box {
  margin-left: 8px;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-box {
  margin-bottom: 8px;
}

/* .tooltipster-content */

.tooltipster-sidetip .tooltipster-content {
  color: #333;
  line-height: 18px;
  img {
    width: 120px;
    margin-bottom: 0.5rem;
  }
  .button {
    position: relative;
    margin: auto;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    display: block;
    padding: 0.5rem;
    width: 50%;
    line-height: 1;
    
  }
}

/* .tooltipster-arrow-background */

.tooltipster-sidetip .tooltipster-arrow-background {
  border: 10px solid transparent;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-background {
  border-bottom-color: #fff;
  left: 0;
  top: 1px;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-arrow-background {
  border-left-color: #fff;
  left: -1px;
  top: 0;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-background {
  border-right-color: #fff;
  left: 1px;
  top: 0;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-background {
  border-top-color: #fff;
  left: 0;
  top: -1px;
}

/* .tooltipster-arrow-border */

.tooltipster-sidetip .tooltipster-arrow-border {
  border: 10px solid transparent;
  left: 0;
  top: 0;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-border {
  border-bottom-color: $gold;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-arrow-border {
  border-left-color: $gold;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-border {
  border-right-color: $gold;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-border {
  border-top-color: $gold;
}
// .noUi-tooltip {
//     display: none;
// }
// .noUi-active .noUi-tooltip {
//     display: block;
// }


.fancybox-slide--image {
  padding-top: 0;
}

.page-blog-list {
  .page-title {
    margin-bottom: 0.5rem;
  }
  .section-blog-filter {
    // padding: 1rem;
    a {
      width: 120px;
      background-color: $gold;
      font-size: 0.875rem;
      padding: 0.5rem 1rem;
      border-radius: 6px;
      margin-bottom: 0;
      &:hover {
        background-color: darken($gold, 10%);
      }
    }
  }
}


.section-benefit-band {
  text-align: center;
  background-color: $gold3;
  color: #fff;
  .section-inner {

  }
  .icon-item {
    padding: 2rem;
  }
  img {
    margin-bottom: 1rem;
    width: 90px;
  }
  strong {
    font-weight: 700;
  }
  p {
    font-weight: 300;
  }
  @include phone {
    font-size: 0.8rem;
    img {
      // width: 60px;
    }
  }
}



button.toggle-aside {
  background: transparent;
  border: solid 2px $primary-color;
  border-top: none;
  color: $body-font-color;
  &:hover, &:active, &:focus {
    background: $primary-color;
    color: $body-font-color;
  }
}


.filter-available-date {
  button {
    margin-top: 0.5rem;
  }
}



.home-slideshow {
  position: relative;
  .slideshow-ribbon {
    position: absolute;
    bottom: -5vw;
    width: 100%;
    @include tablet {
    }
    @include phone {
    }
  }
}

.featured-categories-list {
  .carousel-item {
    position: relative;
    padding-top: 1rem;
  }
  .catclip {
    width: 24px;
    position: absolute;
    top: -12px;
    left: 10px;
    transform: rotate(-15deg);
  }
}

.section-how-it-works {
  padding-top: 90px;
  max-width: 720px;
  margin: auto;
  text-align: center;
  .how-item {
    padding: 1rem 2rem;
    @include phone {
      border-bottom: solid 1px #eee;
      padding-bottom: 45px;
    }
  }
  .image {
    // width: auto;
    padding-top: 150px;
    padding-bottom: 45px;
    margin: auto;
    padding-left: 45px;
    @include phone {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    > img {
      display: inline-block;
    }
    .number {
      width: auto;
      height: 30px;
    }
    .bird {
      width: 90px;
      position: relative;
      top: 18px;
      left: -10px;
    }
  }
  .title {
    font-family: $sans-ff;
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 700;
  }
  .description {
    font-size: 0.8rem;
  }
}

.my-profile {
  max-width: 640px;
  margin: auto;
}

.testimonial-card {
  padding: 2rem;
  border: solid 1px #eee;
  background-color: #FFF;
}

.cart-list-item {
  position: relative;
  padding: 0.5rem;
  border-bottom: solid 1px #ccc;
  margin-bottom: 1rem;
  .title {
    font-weight: 700;
    text-transform: uppercase;
    padding-bottom: 0.5rem;
  }
  .delete {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    
  }
  &:last-child {
    // border-bottom: none;
  }
}

.total-table {
  margin-bottom: 2rem;
}

.page-checkout-completed {
  .order-ref {
    margin: 2rem auto;
  }
  .transfer-amount {
    text-align: center;
    font-weight: 700;
    margin-bottom: 1rem;
    margin-top: 3rem;
  }
}

.order-ref {
  text-align: center;
  span {
    letter-spacing: 2px;
    background-color: #eee;
    border: solid 1px #ccc;
    padding: 0.5rem 2rem;
    text-transform: uppercase;
    margin: auto;
    @include phone {
      letter-spacing: 0;
      padding: 0.25rem 0.5rem;
    }
  }
  font-weight: 700;
  width: auto;
  text-align: center;
}

.order-list {
  font-size: 0.9rem;
  .date {
  }
}



span.label {
  // background-color: #FFF;
  color: #333;
}