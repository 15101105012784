/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */

// Dependencies
@import "../bower_components/foundation-sites/_vendor/normalize-scss/sass/normalize";
@import "../bower_components/foundation-sites/_vendor/sassy-lists/stylesheets/helpers/missing-dependencies";
@import "../bower_components/foundation-sites/_vendor/sassy-lists/stylesheets/helpers/true";
@import "../bower_components/foundation-sites/_vendor/sassy-lists/stylesheets/functions/contain";
@import "../bower_components/foundation-sites/_vendor/sassy-lists/stylesheets/functions/purge";
@import "../bower_components/foundation-sites/_vendor/sassy-lists/stylesheets/functions/remove";
@import "../bower_components/foundation-sites/_vendor/sassy-lists/stylesheets/functions/replace";
@import "../bower_components/foundation-sites/_vendor/sassy-lists/stylesheets/functions/to-list";

// Settings
// import your own `settings` here or
// import and modify the default settings through
// @import 'settings/settings';

// Sass utilities
@import "../bower_components/foundation-sites/scss/util/util";

// Global variables and styles
@import "../bower_components/foundation-sites/scss/global";

// Components
@import "../bower_components/foundation-sites/scss/grid/grid";
@import "../bower_components/foundation-sites/scss/xy-grid/xy-grid";
@import "../bower_components/foundation-sites/scss/typography/typography";
@import "../bower_components/foundation-sites/scss/forms/forms";
@import "../bower_components/foundation-sites/scss/components/visibility";
@import "../bower_components/foundation-sites/scss/components/float";
@import "../bower_components/foundation-sites/scss/components/button";
@import "../bower_components/foundation-sites/scss/components/button-group";
@import "../bower_components/foundation-sites/scss/components/accordion-menu";
@import "../bower_components/foundation-sites/scss/components/accordion";
@import "../bower_components/foundation-sites/scss/components/badge";
@import "../bower_components/foundation-sites/scss/components/breadcrumbs";
@import "../bower_components/foundation-sites/scss/components/callout";
@import "../bower_components/foundation-sites/scss/components/card";
@import "../bower_components/foundation-sites/scss/components/close-button";
@import "../bower_components/foundation-sites/scss/components/drilldown";
@import "../bower_components/foundation-sites/scss/components/dropdown-menu";
@import "../bower_components/foundation-sites/scss/components/dropdown";
@import "../bower_components/foundation-sites/scss/components/flex";
@import "../bower_components/foundation-sites/scss/components/responsive-embed";
@import "../bower_components/foundation-sites/scss/components/label";
@import "../bower_components/foundation-sites/scss/components/media-object";
@import "../bower_components/foundation-sites/scss/components/menu";
@import "../bower_components/foundation-sites/scss/components/menu-icon";
@import "../bower_components/foundation-sites/scss/components/off-canvas";
@import "../bower_components/foundation-sites/scss/components/orbit";
@import "../bower_components/foundation-sites/scss/components/pagination";
@import "../bower_components/foundation-sites/scss/components/progress-bar";
@import "../bower_components/foundation-sites/scss/components/reveal";
@import "../bower_components/foundation-sites/scss/components/slider";
@import "../bower_components/foundation-sites/scss/components/sticky";
@import "../bower_components/foundation-sites/scss/components/switch";
@import "../bower_components/foundation-sites/scss/components/table";
@import "../bower_components/foundation-sites/scss/components/tabs";
@import "../bower_components/foundation-sites/scss/components/title-bar";
@import "../bower_components/foundation-sites/scss/components/top-bar";
@import "../bower_components/foundation-sites/scss/components/thumbnail";
// @import "../bower_components/foundation-sites/scss/components/tooltip";
@import "../bower_components/foundation-sites/scss/prototype/prototype";

@mixin foundation-everything($flex: true, $prototype: false) {
  @if $flex {
    $global-flexbox: true !global;
  }
  @include foundation-global-styles;
  @if not $flex {
    @include foundation-grid;
  }
  @else {
    @if $xy-grid {
      @include foundation-xy-grid-classes;
    }
    @else {
      @include foundation-flex-grid;
    }
  }
  @include foundation-typography;
  @include foundation-forms;
  @include foundation-button;
  @include foundation-accordion;
  // @include foundation-accordion-menu;
  @include foundation-badge;
  @include foundation-breadcrumbs;
  @include foundation-button-group;
  @include foundation-callout;
  @include foundation-card;
  @include foundation-close-button;
  @include foundation-menu;
  // @include foundation-menu-icon;
  // @include foundation-drilldown-menu;
  @include foundation-dropdown;
  @include foundation-dropdown-menu;
  @include foundation-responsive-embed;
  @include foundation-label;
  @include foundation-media-object;
  // @include foundation-off-canvas;
  // @include foundation-orbit;
  @include foundation-pagination;
  // @include foundation-progress-bar;
  // @include foundation-slider;
  // @include foundation-sticky;
  @include foundation-reveal;
  // @include foundation-switch;
  @include foundation-table;
  // @include foundation-tabs;
  @include foundation-thumbnail;
  // @include foundation-title-bar;
  // @include foundation-tooltip;
  // @include foundation-top-bar;
  @include foundation-visibility-classes;
  @include foundation-float-classes;
  @if $flex {
    @include foundation-flex-classes;
  }
  @if $prototype {
    @include foundation-prototype-classes;
  }
}